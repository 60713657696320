import React, { useContext, useState } from 'react';
import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';
import Modal from '../Modal';
import './style.scss';


const NavBar = () => {
    const [show, setShow] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const closeModal = () => {
        setShow(false);
    };

    const openModal = () => {
        setShow(true);
    };

    return (
        <Navbar collapseOnSelect expand="xl" variant="light">
            <Navbar.Brand href="/"><img src="/static/logo.svg" alt="Balsotājs"></img></Navbar.Brand>
            <Modal
                show={show}
                close={closeModal}
                title="Piekļuve liegta!"
                body="Lai pievienotu iniciatīvu, lūdzu autorizējies!"
            />
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/">
                        <div className="fas fa-home" /> Sākums
                    </Nav.Link>
                    <Nav.Link href="/initiatives">
                        <div className="fas fa-map-marked-alt" /> Balsot
                    </Nav.Link>
                    {
                        currentUser
                            ? <Nav.Link href="/initiative/add">
                                <div className="fas fa-edit" /> Iesniegt iniciatīvu
                            </Nav.Link>
                            : <Nav.Link onClick={openModal} href="#">
                                <div className="fas fa-edit" /> Iesniegt iniciatīvu
                            </Nav.Link>
                    }

                </Nav>
                <Nav>
                    <Form inline>
                        <FormControl type="text" placeholder="Meklēt" className="mr-sm-2" disabled />
                        <Button variant="outline-dark" className="my-2" disabled>Meklēt</Button>
                    </Form>
                    {
                        currentUser
                            ? <Nav.Link className="user-button" href="/user">
                                <div className="user-name">
                                    {currentUser.displayName}
                                </div>
                                <img className="user-image" src={currentUser.photoURL} />
                            </Nav.Link>
                            : <Nav.Link className="user-button" href="/login">
                                <div className="user-name">
                                    Pieslēgties
                                </div>
                                <div className="fas fa-user-circle" />
                            </Nav.Link>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
