import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Modal from '../Modal';
import client from '../../client';
import ReadMore from '../ReadMore';
import { AuthContext } from '../../contexts/AuthContext';
import './style.scss';

const InitiativeCard = ({ initiatives }) => {
    const [show, setShow] = useState(false);
    const [wrapper, setWrapper] = useState();

    const Auth = useContext(AuthContext);

    const reloadInitiatives = () => {
        const unproccessedInitiatives = initiatives;
        initiatives = [];
        for (const initiative of unproccessedInitiatives) {
            for (const vote of initiative.votes) {
                if (vote.userId === Auth.currentUser.uid) {
                    initiative.haveVoted = true;
                }
            }
            initiatives.push(initiative);
        }
    };

    const handleVote = async (initiativeId) => {
        const result = await client.initiatives.addVote(initiativeId, Auth.currentUser.uid);

        if (result.status === 409) {
            return;
        }
        setShow(true);
    };

    const closeModal = () => {
        setShow(false);
        window.location.reload();
    };

    const getWrapperWidth = () => {
        if (wrapper) {
            // console.log('get wrapper width', window.getComputedStyle(wrapper).getPropertyValue('width'));
        } else {
            // console.log('get wrapper - no wrapper');
        }
    };

    useEffect(() => {
        if (Auth.currentUser) {
            reloadInitiatives();
        }
    });

    return (
        <div>
            {
                initiatives.length !== 0
                    ? initiatives.map((initiative) => (
                        <div key={initiative.id} className="initiative-card card mb-3">
                            <div className="row no-gutters">
                                <div className="col-md-4 initiative-card-img-border">
                                    <img src="/static/checkmark.png" className="card-img" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title">{initiative.title}</h5>
                                        <p className="card-text" ref={(node) => setWrapper(node)}>
                                            <ReadMore
                                                text={initiative.description}
                                                numberOfLines={4}
                                                lineHeight={1.4}
                                                showLessButton={true}
                                                onContentChange={getWrapperWidth}
                                            />
                                        </p>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <small className="text-muted">Iesniegts: {initiative.createdAt}</small>
                                        </li>
                                        <li className="list-group-item">
                                            {
                                                Auth.currentUser
                                                    ? <div>
                                                        {
                                                            initiative.haveVoted
                                                                ? <div>Jau nobalsots</div>
                                                                : <Card.Link href="#">
                                                                    <Button variant="secondary" onClick={() => handleVote(initiative.id)}>
                                                                        BALSOT
                                                                    </Button>
                                                                </Card.Link>
                                                        }
                                                    </div>
                                                    : <Button variant="secondary" href="/login">
                                                        Lai balsotu, pieslēdzies!
                                                    </Button>
                                            }
                                        </li>
                                        <li className="list-group-item">Jau nobalsojuši {initiative.votes.length} cilvēki</li>
                                    </ul>
                                </div>
                            </div>
                            <Modal
                                show={show}
                                close={closeModal}
                                title="Veiksmīgi nobalsots!"
                                body="Esi veiksmīgi nobalsojis!"
                            />
                        </div>
                    ))
                    : <Row><Col>Nav publicētu inicitatīvu</Col></Row>
            }
        </div>
    );
};

export default InitiativeCard;
