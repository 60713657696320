import axios from 'axios';

export default {
    async getAll() {
        const result = await axios.get(process.env.REACT_APP_API_URL + '/initiatives');
        return result;
    },

    async getById(id) {
        const result = await axios.get(process.env.REACT_APP_API_URL + '/initiatives' + id);
        return result;
    },

    async getAllConfirmed() {
        const result = await axios.get(process.env.REACT_APP_API_URL + '/initiatives');

        const confirmedInitiatives = [];

        for (const row of result.data) {
            if (row.isConfirmed) {
                confirmedInitiatives.push(row);
            }
        }

        return confirmedInitiatives;
    },

    async addInitiative(data) {
        const result = await axios.post(process.env.REACT_APP_API_URL + '/initiatives', data);
        return result;
    },

    async addVote(initiativeId, userId) {
        const data = {
            user_id: userId,
        };
        const result = await axios.post(process.env.API_URL + '/initiatives/' + initiativeId + '/vote', data);
        return result;
    },

};
