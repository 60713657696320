import React from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import client from '../../client';
import { AuthContext } from '../../contexts/AuthContext';
import './style.scss';

class AddInitiative extends React.Component {
    static contextType = AuthContext;
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.popupMessage = this.popupMessage.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.state = {
            titleError: '',
            descriptionError: '',
            submitionCompleted: false,
        }
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!this.validateForm(event)) {
            return;
        }

        const initiative = {
            title: event.target.elements.title.value,
            description: event.target.elements.description.value,
            author_id: this.context.currentUser.uid,
        };

        const result = await client.initiatives.addInitiative(initiative);

        if (result.status === 201) {
            this.setState({
                submitionCompleted: true
            })
            this.popupMessage(true, 'Iniciatīva veiksmīgi iesūtīta!');
        } else {
            this.popupMessage(true, 'Notikusi kļūda');
        }
        console.log(result);
    }

    popupMessage(success, message) {

    };

    validateForm(event) {
        let isValid = true;

        if (event.target.elements.title.value === '') {
            this.setState({ titleError: 'Lūdzu ievadiet iniciatīvas nosaukumu!' });
            isValid = false;
        }

        if (event.target.elements.description.value === '') {
            this.setState({ descriptionError: 'Lūdzu ievadiet iniciatīvas aprakstu!' })
            isValid = false;
        }

        return isValid;

    };

    render() {
        if (this.state.submitionCompleted) {
            return (
                <main className="container text-center">
                    <Row>
                        <Col className="submit-success">
                            Iniciatīva veiksmīgi iesniegta!<br />
                            Paldies, ka izrādat interesi sabiedrības labklājības uzlabošanā!
                        </Col>
                    </Row>
                    <Row>
                        <Col className="submit-success">
                            <Button variant="secondary" type="submit" href="/">Atgriezties uz sākumu</Button>
                            <Button variant="secondary" type="submit" href="/initiatives">Iet uz iniciatīvu sarakstu</Button>
                        </Col>
                    </Row>
                </main>
            )
        }
        return (
            <main className="justify-content-md-center container add-initiative">
                <Row><br></br></Row>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <Form.Label><b>Pašvaldība</b></Form.Label>
                        <Form.Control as="select" disabled>
                            <option selected>Bauskas pašvaldība</option>
                            <option>...</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                            Pašlaik iniciatīvas var iesniegt tikai Bauskas pašvaldībai.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Iniciatīvas nosaukums</b></Form.Label>
                        <Form.Control name="title" type="text" placeholder="Iniciatīvas nosaukums" />
                        {this.state.titleError}
                        <Form.Text className="text-muted">
                            Izvēlieties iniciatīvas nosaukumu, kas trāpīgi atspoguļo tās galveno risinājumu!
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Iniciatīvas apraksts</b></Form.Label>
                        <Form.Control name="description" as="textarea" rows="5" placeholder="Iniciatīvas apraksts" />
                        {this.state.descriptionError}
                        <Form.Text className="text-muted">
                            Aprakstiet esošo situāciju, kuru vēlaties mainīt, kā arī kas, jūsuprāt, esošajā regulējumā nodara kaitējumu sabiedrības interesēm.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Autors</b></Form.Label>
                        <Form.Control name="author" type="text" value={this.context.currentUser.displayName} placeholder="Autors" disabled/>
                        <Form.Text className="text-muted">
                            Iniciatīvas autors būsiet Jūs.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Text className="text-muted">
                            Iesniegtā iniciatīva tiks izskatīta 3 darba dienu laikā.
                        </Form.Text>
                    </Form.Group>
                    <Button variant="secondary" type="submit">Iesniegt iniciatīvu</Button>
                </Form>
            </main>
        );
    }
}

export default AddInitiative;

