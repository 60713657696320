import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import AddInitiative from './components/AddInitiative';
import Home from './pages/Home';
import LoginView from './pages/LoginView';
import UserView from './pages/UserView';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRouteHoc from './contexts/ProtectedRouteHoc';
import InitiativesView from './pages/InitiativesView';
import Footer from './components/Footer';
import Banner from './components/Banner';
import './styles/general-styles.scss';

class App extends React.Component {
    constructor() {
        super();

        const user = window.sessionStorage.getItem(
            'firebase:authUser:AIzaSyBqUxvnWFR90J0xP0JJuR8njbeRC7Jc1Kw:[DEFAULT]',
        );

        if (user) {
            this.state = {
                isLoggedIn: true,
                currentUser: user,
            };
        } else {
            this.state = {
                isLoggedIn: false,
                currentUser: null,
            };
        }
    }

    render() {
        return (
            <AuthProvider>
                <Router>
                    <>
                        <Banner />
                        <NavBar />
                        <Route exact path="/" component={Home} />
                        <Route exact path="/initiatives" component={InitiativesView} />
                        <Route exact path="/login" component={LoginView} />
                        <ProtectedRouteHoc
                            isLoggedIn={this.state.isLoggedIn}
                            path='/user'
                            component={UserView}
                        />
                        <ProtectedRouteHoc
                            isLoggedIn={this.state.isLoggedIn}
                            path='/initiative/add'
                            component={AddInitiative}
                        />
                        <Footer />
                    </>
                </Router>
            </AuthProvider>
        );
    }
}

export default App;
