import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.scss';

const Instructions = () => (
    <div id="instructions" className="instructions container">
        <Row>
            <Col className="logo">
                <img src='/static/checkmark.png' />
            </Col>
        </Row>
        <Row>
            <Col>
                <h2 className="title">Kā tas strādā?</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="instructions-card">
                    <div className="instructions-card-title">
                        Inicitatīvas iesniegšana
                    </div>
                    <div className="instructions-card-body">
                        <div className="instructions-card-step">
                            <div className="number-box">
                                <div className="number">
                                    1
                                </div>
                            </div>
                            <div className="icon">
                                <div className="fab fa-google" />
                            </div>
                            <div className="step-description">
                                Pieslēdzies ar Google kontu
                            </div>
                        </div>
                        <div className="instructions-card-step">
                            <div className="number-box">
                                <div className="number">
                                    2
                                </div>
                            </div>
                            <div className="icon">
                                <div className="fas fa-edit" />
                            </div>
                            <div className="step-description">
                                Spied "Iesniegt iniciatīvu"
                            </div>
                        </div>
                        <div className="instructions-card-step">
                            <div className="number-box">
                                <div className="number">
                                    3
                                </div>
                            </div>
                            <div className="icon">
                                <div className="fas fa-pen" />
                            </div>
                            <div className="step-description">
                                Ievadi nosakumu, aprakstu
                            </div>
                        </div>
                        <div className="instructions-card-step">
                            <div className="number-box">
                                <div className="number">
                                    4
                                </div>
                            </div>
                            <div className="icon">
                                <div className="fas fa-hourglass-half" />
                            </div>
                            <div className="step-description">
                                Iesniedz iniciatīvu
                            </div>
                        </div>
                    </div>
                    <div className="instructions-card-footer">
                        Inicitatīva <b>3 darbadienu laikā</b> tiks izskatīta un publicēta!<br />
                        Atceries, ka mēneša laikā vari iesniegt tikai <b>2 iniciatīvas</b>!
                    </div>
                </div>
            </Col>
            <Col>
                <div className="instructions-card">
                    <div className="instructions-card-title">
                        Balsošana
                    </div>
                    <div className="instructions-card-body">
                        <div className="instructions-card-step">
                            <div className="number-box">
                                <div className="number">
                                    1
                                </div>
                            </div>
                            <div className="icon">
                                <div className="fab fa-google" />
                            </div>
                            <div className="step-description">
                                Pieslēdzies ar Google kontu
                            </div>
                        </div>
                        <div className="instructions-card-step">
                            <div className="number-box">
                                <div className="number">
                                    2
                                </div>
                            </div>
                            <div className="icon">
                                <div className="fas fa-person-booth" />
                            </div>
                            <div className="step-description">
                                Spied "Sākt"
                            </div>
                        </div>
                        <div className="instructions-card-step">
                            <div className="number-box">
                                <div className="number">
                                    3
                                </div>
                            </div>
                            <div className="icon">
                                <div className="fas fa-vote-yea" />
                            </div>
                            <div className="step-description">
                                Ja atbalsti kādu no tām, spied "Balsot"
                            </div>
                        </div>
                    </div>
                    <div className="instructions-card-footer">
                        Atceries, ka mēneša laikā vari balsot tikai par <b>2 iniciatīvām</b>!
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <br/><br/>
        </Row>

    </div>
);

export default Instructions;
