import React, { useContext, useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import firebase from 'firebase';

import { AuthContext } from '../contexts/AuthContext';
import './loginView.scss';

const LoginView = ({ history, isLoggedIn }) => {
    const [error, setErrors] = useState('');

    const Auth = useContext(AuthContext);

    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                firebase
                    .auth()
                    .signInWithPopup(provider)
                    .then((result) => {
                        console.log(result);
                        history.push('/initiatives');
                        Auth.setLoggedIn(true);
                    })
                    .catch((e) => setErrors(e.message));
            });
    };

    useEffect(() => {
        if (Auth.currentUser) {
            history.push('/user');
        }
    });

    return (
        <main className="container">
            <Row className="login-page">
                <div className="login-title">
                    <h1>Pieslēdzies</h1>
                </div>
                <div className='login-button'>
                    <img src="/static/btn_google_light_normal_ios.svg" onClick={() => signInWithGoogle()} />
                </div>
            </Row>
        </main>
    );
};

export default LoginView;
