import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './style.scss';
import { AuthContext } from '../../contexts/AuthContext';

class Footer extends React.Component {
    static contextType = AuthContext;

    render() {
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <Col>
                            Visas tiesības rezervētas. Pārpublicēšanas gadījumā saite uz balsotajs.id.lv ir obligāta.
                            <br />Paldies dizainerim <u>Edvardam Markevičam</u>
                        </Col>
                        <Col className="logo col-3"><img src="/static/checkmark.png" /></Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default Footer;
