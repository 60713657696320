import React from 'react';
import { Row, Container } from 'react-bootstrap';
import './style.scss';

const Banner = () => (
    <div className="banner">
        <Container>
            <Row>
                <div className="col-10">
                    <b>Mājaslapa ir izstrādes stadijā. Ievietotās inicitatīvas ir tikai piemēri.</b>
                    <br/>Par ieteikumiem un uzlabojumiem rakstiet <a className="email-link" href = "mailto: karlis.gustavs.logins@gmail.com">karlis.gustavs.logins@gmail.com</a>
                    &nbsp;vai mūsu Facebook lapā.
                </div>
                <div className="col-2 fb-messenger">
                    <a href="http://m.me/balsotajs">
                        <i className="fab fa-facebook-messenger" />
                    </a>
                </div>
            </Row>
        </Container>
    </div>
);

export default Banner;
