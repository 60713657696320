import React, { useContext } from 'react';
import firebase from 'firebase';
import { Row, Col } from 'react-bootstrap';
import { AuthContext } from '../contexts/AuthContext';
import './UserView.scss';

const LoginView = ({ history }) => {
    const Auth = useContext(AuthContext);

    const signOut = () => {
        firebase.auth().signOut();
        history.push('/');
    };

    return (
        <main className="container">
            <Row>
                <br /><br />
            </Row>
            <Row>
                <Col>
                    <h1>Lietotāja informācija</h1>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <img className="user-image" src={Auth.currentUser.photoURL} />
                </Col>
                <Col className="user-info">
                    <p>Vārds, Uzvārds: <b>{Auth.currentUser ? Auth.currentUser.displayName : null}</b></p>
                    <button onClick={() => signOut()} className="btn btn-outline-danger" type="button">
                        Iziet no profila
                    </button>
                </Col>
            </Row>
        </main>
    );
};

export default LoginView;
