import React, { useEffect, useState } from 'react';
import firebaseApp from '../config/firebaseConfig';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const user = window.sessionStorage.getItem(
        'firebase:authUser:AIzaSyBqUxvnWFR90J0xP0JJuR8njbeRC7Jc1Kw:[DEFAULT]',
    );

    const [currentUser, setCurrentUser] = useState(JSON.parse(user));

    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged(setCurrentUser);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                currentUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

