import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import InitiativeCard from '../InitiativeCard';
import client from '../../client';
import './style.scss';

class Body extends React.Component {
    state = {
        initiatives: []
    }

    async componentDidMount() {
        const initiatives = await client.initiatives.getAllConfirmed();
        this.setState({ initiatives: initiatives });
    }

    render() {
        return (
            <div className="initiative-list">

                <Container>
                    <Row className="initiative-list-title">
                        <Col><h2>Bauska</h2></Col>
                    </Row>
                    <Row>
                        <InitiativeCard initiatives={this.state.initiatives} />
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Body;
