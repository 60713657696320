import React from 'react';
import { Jumbotron, Container, Button } from 'react-bootstrap';
import './style.scss';
import { AuthContext } from '../../contexts/AuthContext';

class Hero extends React.Component {
    static contextType = AuthContext;

    render() {
        return (
            <Jumbotron fluid style={{backgroundImage: 'url(./static/hero-riga.jpg)'}}>
                <Container>
                    <h1>BALSOTĀJS</h1>
                    <h4>Balso sava novada nākotnei</h4>
                    {
                        this.context.currentUser
                            ? <Button variant="outline-light" className="my-2" href="/initiatives">Sākt</Button>
                            : <Button variant="outline-light" className="my-2" href="/login">Sākt</Button>
                    }
                    &nbsp; &nbsp;
                    <Button variant="outline-light" className="my-2" href="#instructions">Kā tas strādā?</Button>
                </Container>
            </Jumbotron>
        );
    }
}
export default Hero;
