import React from 'react';
import InitiativeList from '../components/InitiativeList';

import 'bootstrap/dist/css/bootstrap.min.css';

class Home extends React.Component {
    render() {
        return (
            <main>
                <InitiativeList />
            </main>
        );
    }
}

export default Home;
