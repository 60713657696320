import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const modal = (props) => (
    <div>
        <Modal show={props.show} onHide={props.close}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.close}>
                    Aizvērt
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
);

export default modal;
