import React from 'react';
import Hero from '../components/Hero';
import Instructions from '../components/Instructions';
import 'bootstrap/dist/css/bootstrap.min.css';

class Home extends React.Component {
    render() {
        return (
            <div className="main">
                <Hero />
                <Instructions />
            </div>
        );
    }
}

export default Home;
